import manage from "@/api/shop/plugin/manage";
import knowledge from "@/api/shop/plugin/knowledge";
import areaAgent from "@/api/shop/plugin/area-agent";
import dealer from "@/api/shop/plugin/dealer";
import cmo from "@/api/shop/plugin/cmo";
import shareholder from "@/api/shop/plugin/shareholder";
import teamManage from "@/api/shop/plugin/team-manage";
import scanGoods from "@/api/shop/plugin/scan-goods";
import privilegeCard from "@/api/shop/plugin/privilege-card";
import decorateLevel from "@/api/shop/plugin/decorate-level";
import operationCenter from "@/api/shop/plugin/operation-center";
import franchiseStore from "@/api/shop/plugin/franchise-store";
import capital from "@/api/shop/plugin/capital";
import article from "@/api/shop/plugin/article";
import pointsMall from "@/api/shop/plugin/points-mall";
import blindAward from "@/api/shop/plugin/blind-award";
export default {
    manage,
    knowledge,
    areaAgent,
    dealer,
    cmo,
    shareholder,
    teamManage,
    scanGoods,
    privilegeCard,
    decorateLevel,
    operationCenter,
    franchiseStore,
    capital,
    article,
    pointsMall,
    blindAward,
}