import knowledge from "@/router/shop/plugin/knowledge";
import areaAgent from "@/router/shop/plugin/area-agent";
import dealer from "@/router/shop/plugin/dealer";
import cmo from "@/router/shop/plugin/cmo";
import shareholder from "@/router/shop/plugin/shareholder";
import teamManage from "@/router/shop/plugin/team-manage";
import scanGoods from "@/router/shop/plugin/scan-goods";
import privilegeCard from "@/router/shop/plugin/privilege-card";
import decorateLevel from "@/router/shop/plugin/decorate-level";
import operationCenter from "@/router/shop/plugin/operation-center";
import franchiseStore from "@/router/shop/plugin/franchise-store";
import capital from "@/router/shop/plugin/capital";
import pointsMall from "@/router/shop/plugin/points-mall";
import blindAward from "@/router/shop/plugin/blind-award";

export default {
    path: "plugin",
    component: () => import("@/view/shop/plugin/index"),
    redirect: "/shop/plugin/manage",
    name: "shop-plugin",
    meta: {
        title: "插件"
    },
    children: [
        {
            path: "manage",
            name: "shop-plugin-manage",
            component: () => import("@/view/shop/plugin/manage"),
            meta: {
                title: "插件中心"
            }
        },
        knowledge,
        areaAgent,
        dealer,
        cmo,
        shareholder,
        teamManage,
        scanGoods,
        privilegeCard,
        decorateLevel,
        operationCenter,
        franchiseStore,
        capital,
        pointsMall,
        blindAward,
    ]
}